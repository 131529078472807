import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, DateRangePicker, Kind, Size } from "@usitsdasdesign/dds-react";
import { Checkbox, BlockScreenMsg, Notify, PopUpModel, Dropdown } from "global";
import { ThemeContext } from "theme/themeContext";
import "./GenerateReport.scss";
import { CALL_NOTIFY, SHOWSCREENBLOCKMSG } from "global/store/action";
import { useDispatch, useSelector } from "react-redux";
import { roleEnum } from "global/constants/Enums";
import { RootState } from "app/store";
import { getAllusers } from "services/orgLevelService";
import ReportService from "services/ReportService";
import UserService from "services/UserService";
import {
  InputBox,
  validateRequired,
  validateFileName,
} from "global/components/inputBox/InputBox";
import { getStartOfDay } from "global/utils/DateUtil";
import ColumnsMap from "../columnsMap";

interface GenerateReportProps {
  editReportId: string;
  viewOnly: boolean;
  singleUserReportEmail: string | null;
}

interface IOption {
  label: string;
  value: string;
}

interface IDropdownProps {
  functionalArea: IOption[];
  department: IOption[];
  subDepartment: IOption[];
}

interface IColumn {
  name: string;
  type: "categories" | "hours" | "userDetails";
  id?: string;
}

const BASE_CLASS = "generateReport";
const baseDate = new Date(new Date().setDate(new Date().getDate() - 1));

const GenerateReport: React.FC<GenerateReportProps> = ({
  editReportId,
  viewOnly = false,
  singleUserReportEmail,
}) => {
  const dispatch = useDispatch();
  const { themeObjState } = useContext(ThemeContext);

  const [functionalAreas, setFunctionalAreas] = useState<IOption[]>([]);
  const [departments, setDepartments] = useState<IOption[]>([]);
  const [subDepartments, setSubDepartments] = useState<IOption[]>([]);
  const [locations, setLocations] = useState<IOption[]>([]);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [allData, setAllData] = useState<Record<string, any>>([]);

  const [email, setEmail] = useState<string>('');
  const [singleUserDetails, setSingleUserDetails] = useState<any>();
  const [IsValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<IColumn[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [showGenerateModal, setShowGenerateModal] = useState<boolean>(false);
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);

  const LoginUserData: IUser = useSelector(
    (state: RootState) => state.globalReducer.loginUser
  );

  const getInitValues = () => ({
    functionalArea: [],
    department: [],
    subDepartment: [],
  });

  const [dropdownValues, setDropdownValues] = useState<IDropdownProps>({
    functionalArea: [],
    department: [],
    subDepartment: [],
  });

  const [formValidity, setFormValidity] = useState({
    "Report name": false,
  });

  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: baseDate,
    endDate: baseDate,
  }); // date range for filtering data

  useEffect(() => {
    if (LoginUserData.entitlements) {
      getData();
    }
  }, [LoginUserData]);

  useEffect(() => {
    const trimmedEmail = email.trim();
    if (trimmedEmail.length > 0 && isValidEmailRegex(trimmedEmail)) {
      // valid email id with '@deloitte.com'
      try {
        UserService.findByEmailWithEntitlements(trimmedEmail).then((res: any) => {
          if (res.data.data.length === 0) {
            // user not found
            setIsValidEmail(false);
            setDropdownValues(getInitValues());
            setSelectedLocations([]);
          } else {
            // user found
            const userDetails = res.data.data[0];

            setIsValidEmail(true);
            getDepts(userDetails.functionalArea, allData, false);
            getSubDepts(
              userDetails.functionalArea,
              userDetails.department,
              allData,
              false
            );

            setDropdownValues({
              functionalArea: [{
                label: userDetails.functionalArea,
                value: userDetails.functionalArea,
              }],
              department: [{
                label: userDetails.department,
                value: userDetails.department,
              }],
              subDepartment: [{
                label: userDetails.subDepartment,
                value: userDetails.subDepartment,
              }]
            });
            setSelectedLocations([userDetails.officeLocation]);
          }
        }).catch(() => {
          setIsValidEmail(false);
          setDropdownValues(getInitValues());
          setSelectedLocations([]);
        });
      } catch (er) {
        // user not found
        setIsValidEmail(false);
        setDropdownValues(getInitValues());
        setSelectedLocations([]);
      }
    } else {
      // invalid email
      setIsValidEmail(false);
      setDropdownValues(getInitValues());
      setSelectedLocations([]);
    }
  }, [email]);

  const isValidEmailRegex = (inputEmail: any) => {
    return /^[A-Za-z0-9._%+-]+@deloitte\.com$/.test(inputEmail);
  };

  const getSingleUserDetails = (email: string) => {
    if (singleUserReportEmail !== null) {
      try {
        UserService.findByEmail(email).then((res: any) => {
          setSingleUserDetails(res?.data.data[0]);

          const userDetails = res.data.data[0];

          setDropdownValues({
            functionalArea: [{
              label: userDetails.functionalArea,
              value: userDetails.functionalArea,
            }],
            department: [{
              label: userDetails.department,
              value: userDetails.department,
            }],
            subDepartment: [{
              label: userDetails.subDepartment,
              value: userDetails.subDepartment,
            }]
          });
          setSelectedLocations([userDetails.officeLocation]);
        });
      } catch (er) {
        // user not found
        setSingleUserDetails({});
      }
    }
  };

  const editReport = async (reportId: string, tempAllData: any) => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });

      await ReportService.getReportById(reportId).then((res: any) => {
        const resData = res.data.data[0];
        setDateRange({
          startDate: new Date(resData.StartDate),
          endDate: new Date(resData.EndDate),
        });

        if (resData.userEmail !== null) {
          getSingleUserDetails(resData.userEmail);
        } else {
          const functionalEntitlements = LoginUserData.role === roleEnum.ENTITLED
            ? LoginUserData.entitlements?.levels.filter(
              (item) => item.functionalArea)
              .map((item) => item.functionalArea)
            : []

          const tempFunctionalAreas = JSON.parse(resData.functionalArea);

          // load dropdown values only in case of:
          // master user, view report, or if entitlements are same as previously selected functional area
          if (LoginUserData.role === roleEnum.MASTER
            || viewOnly
            || (LoginUserData.role === roleEnum.ENTITLED && functionalEntitlements?.length === tempFunctionalAreas.length &&
              functionalEntitlements?.slice().sort().every((value, index) => value === tempFunctionalAreas.slice().sort()[index]))
          ) {
            getDepts(JSON.parse(resData.functionalArea), tempAllData, false);
            getSubDepts(
              JSON.parse(resData.functionalArea),
              JSON.parse(resData.department),
              tempAllData,
              false
            );

            setDropdownValues({
              functionalArea: JSON.parse(resData.functionalArea).map((val: any) => ({
                label: val,
                value: val,
              })),
              department: JSON.parse(resData.department).map((val: any) => ({
                label: val,
                value: val,
              })),
              subDepartment: JSON.parse(resData.subDepartment).map((val: any) => ({
                label: val,
                value: val,
              })),
            });
          }

          setSelectedLocations(JSON.parse(resData.location));
        }

        const parsedCols = JSON.parse(resData.selection);
        const allColumns: IColumn[] = [
          ...parsedCols.categories.map((category: any) => ({
            name: category.name,
            type: "categories",
            id: category.id,
          })),
          ...parsedCols.hours.map((hour: any) => ({
            name: hour,
            type: "hours",
          })),
          ...parsedCols.userDetails.map((userDetail: any) => ({
            name: userDetail,
            type: "userDetails",
          })),
        ];
        setSelectedColumns(allColumns);
      });
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    } catch (ex: any) {
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: "Something went wrong. Please try again later.",
          timeout: 3000,
        },
      });
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    }
  };

  const getData = async () => {
    const params = {
      selection: "functionalArea,department,subDepartment,officeLocation",
    };
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      let tempData = {};
      await getAllusers(params).then((res: any) => {
        const resData = res.data.data.record;
        tempData = resData;
        setAllData(resData);
        getLocations(resData);
        getFunctionalAreas(resData);
      });
      getColumns();
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      if (editReportId) {
        editReport(editReportId, tempData);
      } else {
        setDropdownValues(getInitValues());
      }
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Something went wrong. Please try again later.`,
          timeout: 3000,
        },
      });
    }
  };

  const getLocations = (resData: any) => {
    setLocations(
      (
        [
          ...new Set(resData.map((item: IUser | any) => item.officeLocation)),
        ] as string[]
      )
        .sort()
        .map((item) => ({ label: item, value: item }))
    );
  };

  const getFunctionalAreas = (resData: any) => {
    const temp = (
      [
        ...new Set(
          LoginUserData.entitlements?.levels.some(
            (item) => item.functionalArea === "*"
          ) || LoginUserData.role === roleEnum.MASTER
            ? resData.map((item: IUser | any) => item.functionalArea)
            : LoginUserData.entitlements?.levels
              // .filter((item) => item.functionalArea && !item.department && !item.subDepartment)
              .map((item) => item.functionalArea)
        ),
      ] as string[]
    )
      .sort()
      .map((item) => ({ label: item, value: item }));
    setFunctionalAreas(temp);
  };

  const handleFunctionChange = (selected: any) => {
    setDropdownValues((prev) => ({
      ...prev,
      functionalArea: selected,
      department: [],
      subDepartment: [],
    }));

    if (selected.length === 1) {
      getDepts(
        selected.map((item: IOption) => item.value),
        allData
      );
    } else {
      getDepts([], allData); //check
      setDepartments([]);
      setSubDepartments([]);
    }
  };

  const handleDepartmentChange = (selected: any) => {
    setDropdownValues((prev) => ({
      ...prev,
      department: selected,
      subDepartment: [],
    }));

    if (selected.length === 1) {
      getSubDepts(
        dropdownValues?.functionalArea.map((item) => item.value),
        selected.map((item: IOption) => item.value),
        allData
      );
    } else {
      setSubDepartments([]);
    }
  };

  const handleSubDepartmentChange = (selected: any) => {
    setDropdownValues((prev) => ({
      ...prev,
      subDepartment: selected,
    }));
  };

  const getDepts = (
    functionalAreaStrings: string[],
    resData: any,
    isUpdata: boolean = true
  ) => {
    const tempData = resData.filter((item: any) =>
      functionalAreaStrings.includes(item.functionalArea)
    );

    setSubDepartments([]);
    if (tempData.length === 0) {
      setDepartments([]);
      return;
    }

    const tempEn = LoginUserData.entitlements?.levels
      .filter((item) => functionalAreaStrings.includes(item.functionalArea))
      .map((item: { department: string }) => item.department) as string[];

    const tempSubDepts = tempData.map(
      (item: IUser | any) => item.department
    ) as string[];

    const ttDepts = (
      [
        ...new Set(
          tempEn?.some((item: string) => item === "") ||
            LoginUserData.role === roleEnum.MASTER
            ? tempSubDepts
            : tempEn.filter((item) => tempSubDepts.includes(item))
        ),
      ] as string[]
    )
      .sort()
      .map((item) => ({ label: item, value: item }));

    setDepartments(ttDepts);

    if (isUpdata) {
      setDropdownValues((prev) => ({
        ...prev,
        functionalArea: functionalAreaStrings.map((item) => ({
          label: item,
          value: item,
        })),
        department: ttDepts,
        subDepartment: [],
      }));
    }
  };

  const getSubDepts = (
    functionalAreaStrings: string[],
    departmentStrings: string[],
    resData: any,
    isUpdata: boolean = true
  ) => {
    const tempData = resData.filter(
      (item: IUser | any) =>
        functionalAreaStrings.includes(item.functionalArea) &&
        departmentStrings.includes(item.department) &&
        item.subDepartment
    );

    if (tempData.length === 0) {
      setSubDepartments([]);
      return;
    }
    const tempEn = LoginUserData.entitlements?.levels
      .filter((item) => functionalAreaStrings.includes(item.functionalArea))
      .map((item: { subDepartment: string }) => item.subDepartment) as string[];

    const tempSubDepts = tempData.map(
      (item: IUser | any) => item.subDepartment
    ) as string[];

    const tttSubdepts = (
      [
        ...new Set(
          tempEn?.some((item) => item === "") ||
            LoginUserData.role === roleEnum.MASTER
            ? tempSubDepts
            : tempEn.filter((item) => tempSubDepts.includes(item))
        ),
      ] as string[]
    )
      .sort()
      .map((item) => ({ label: item, value: item }));

    setSubDepartments(tttSubdepts);
    isUpdata &&
      setDropdownValues((prev) => ({
        ...prev,
        functionalArea: functionalAreaStrings.map((item) => ({
          label: item,
          value: item,
        })),
        department: departmentStrings.map((item) => ({
          label: item,
          value: item,
        })),
        subDepartment: tttSubdepts,
      }));
  };

  const getColumns = async () => {
    const params = {
      columns: true,
    };
    try {
      await ReportService.getAll(params).then((res: any) => {
        const resData = res.data.data;
        const allColumns: IColumn[] = [
          ...resData.categories.map((category: any) => ({
            name: category.name,
            type: "categories",
            id: category.id,
          })),
          ...resData.hours.map((hour: any) => ({
            name: hour,
            type: "hours",
          })),
          ...resData.userDetails.map((userDetail: any) => ({
            name: userDetail,
            type: "userDetails",
          })),
        ];

        setColumns(allColumns);
        if (!editReportId) {
          setSelectedColumns(allColumns);
        }
      });
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Something went wrong. Please try again later.`,
          timeout: 3000,
        },
      });
    }
  };

  const getPlaceholderValues = () => {
    if (dropdownValues.functionalArea.length < 1) {
      return "Select";
    } else if (dropdownValues.functionalArea.length > 1) {
      return "All";
    }

    if (dropdownValues.department.length < 1) {
      return "Select";
    } else if (dropdownValues.department.length > 1) {
      return "All";
    }

    return "";
  };

  const validateOptions = () => {
    const errorMessage =
      selectedLocations.length === 0
        ? "Please select at least one location to generate the report."
        : selectedColumns.length === 0
          ? "Please select at least one column to generate the report."
          : "";

    if (errorMessage.length > 0) {
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: errorMessage,
          timeout: 3000,
        },
      });
      return false;
    }
    return true;
  };

  const generateReport = async () => {
    const selectedCols = {
      categories: [] as {
        id: string;
        name: string;
      }[],
      hours: [] as string[],
      userDetails: [] as string[],
    };

    selectedColumns.forEach((col) => {
      if (col.type === "categories" && col.id) {
        selectedCols.categories.push({
          id: col.id,
          name: col.name,
        });
      } else if (col.type === "hours") {
        selectedCols.hours.push(col.name);
      } else if (col.type === "userDetails") {
        selectedCols.userDetails.push(col.name);
      }
    });

    const data = IsValidEmail ? {
      name: fileName,
      userEmail: email,
      startDate: getStartOfDay(dateRange.startDate),
      endDate: getStartOfDay(dateRange.endDate),
      selection: selectedCols,
    } : {
      name: fileName,
      functionalArea: dropdownValues.functionalArea.map((val) => val.value),
      department: dropdownValues.department.map((val) => val.value),
      subDepartment: dropdownValues.subDepartment.map((val) => val.value),
      location: selectedLocations,
      startDate: getStartOfDay(dateRange.startDate),
      endDate: getStartOfDay(dateRange.endDate),
      selection: selectedCols,
    };

    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      await ReportService.generateReport(data).then(() => {
        dispatch({
          type: CALL_NOTIFY,
          payload: {
            type: "SUCCESS",
            msg: `Report generation started.`,
            timeout: 3000,
          },
        });
      });
      setShowEmailModal(true);
      resetPage();
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    } catch (error: any) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg:
            error.response.data.message ||
            `Error exporting data. Please try again later.`,
          timeout: 3000,
        },
      });
    }
  };

  /**
   * method to get HTML for displaying view only items with checkboxes in view report
   * @param displayList array of objects which is to be displayed (should have label property)
   * @param showAllIfEmpty if array is empty, 'All' button will be shown
   * @returns HTML- checkbox (ticked) and label
   */
  const displaySelectedItems = (displayList: any[], showAllIfEmpty: boolean = false) => {
    return (
      <>
        {displayList.length ? (
          <>
            <div className={`${BASE_CLASS}-section-checkbox-list`}>
              {displayList.map((item, i) => (
                <div
                  className={`${BASE_CLASS}-section-checkbox-viewOnly`}
                  key={i}
                >
                  <Checkbox
                    value={true}
                    onChange={() => { }}
                  />
                  <div className={`${displayList.length > 1 ? 'ellipsis' : ''}`}>
                    {item.label}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            {showAllIfEmpty ? (
              <div className={`${BASE_CLASS}-section-checkbox-single`}>
                <Checkbox
                  value={true}
                  onChange={() => { }}
                />
                <div className="ellipsis">All</div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  };

  const handleUserInputChange = (fieldName: any, isValid: any) => {
    setFormValidity((prevState) => ({
      ...prevState,
      [fieldName]: isValid,
    }));
  };

  const isValidFields = Object.values(formValidity).every((valid) => valid);

  const resetPage = () => {
    setDateRange({
      startDate: baseDate,
      endDate: baseDate,
    });
    editReportId = "";
    setDropdownValues(getInitValues());
    setSelectedLocations([]);
    setSelectedColumns(columns);
    setFunctionalAreas([]);
    setDepartments([]);
    setSubDepartments([]);
    getData();
    setEmail('');
  };

  const disableResetButton =
    email.length === 0 &&
    getStartOfDay(dateRange.startDate) === getStartOfDay(baseDate) &&
    getStartOfDay(dateRange.endDate) === getStartOfDay(baseDate) &&
    JSON.stringify(dropdownValues) === JSON.stringify(getInitValues()) &&
    selectedLocations.length === 0 &&
    selectedColumns.length === columns.length;

  return (
    <>
      <div className={`${BASE_CLASS} ${viewOnly ? 'noPadding' : ''}`}>
        {!viewOnly && (
          <div className={`${BASE_CLASS}-header dds-h5`}>
            Reports
          </div>
        )}

        {viewOnly && singleUserReportEmail !== null && singleUserDetails ? (
          <div className={`${BASE_CLASS}-section`}>
            <div className={`${BASE_CLASS}-manager-container`}>
              <div
                className={`${BASE_CLASS}-manager-image`}
                style={{ backgroundColor: '#63666A' }}
              >
                {singleUserDetails.name.charAt(0) + singleUserDetails.name.split(" ").pop().charAt(0)}
              </div>
              <div className={`${BASE_CLASS}-manager`}>
                <div className="user-name break-word-white-space">
                  {singleUserDetails.name}
                </div>
                <div className="user-email dds-label-sm-light">
                  {singleUserDetails.email}
                </div>
                <div className={`${BASE_CLASS}-jobTitle-container`}>
                  <div
                    className={`${BASE_CLASS}-jobTitle dds-label-sm-light`}
                  >
                    {singleUserDetails.jobTitle}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={`${BASE_CLASS}-section`}>
          <div className={`${BASE_CLASS}-section-header dds-h6`}>
            Date range
          </div>
          <div className={`${BASE_CLASS}-daterange`}>
            <DateRangePicker
              size={Size.l}
              theme={themeObjState}
              maxDate={baseDate}
              labelPosition="external"
              label={"Start and end date"}
              disabledDates={{ after: new Date(), dates: [new Date()] }}
              isManualInput={false}
              format="DD/MM/YYYY"
              icon="calendar__s__stroke"
              customClass="daterange"
              isRequired={true}
              stickerOptions={{
                isDisabled: viewOnly ? true : false
              }}
              value={[dateRange.startDate, dateRange.endDate]}
              dateRangeSelected={(data: string[] | Date[] | null) => {
                if (data && data.length === 2) {
                  setDateRange({
                    startDate: new Date(data[0]),
                    endDate: new Date(data[1]),
                  });
                }
              }}
            />
          </div>
        </div>

        <div className={`${BASE_CLASS}-section`}>
          <div className={`${BASE_CLASS}-section-header dds-h6`}>
            {viewOnly ? (
              <>Authorisation(s)</>
            ) : (
              <>Select user or authorisation</>
            )}
          </div>

          {viewOnly ? (
            <div style={{ display: singleUserReportEmail ? 'flex' : '' }}>
              <div>
                <div className={`${BASE_CLASS}-section dds-h6`} style={{ fontSize: '14px', marginBottom: '1vh' }}>
                  Function
                </div>
                <div className={`${BASE_CLASS}-section-checkbox dds-h7`}>
                  {displaySelectedItems(dropdownValues.functionalArea)}
                </div>
              </div>
              <div>
                <div className={`${BASE_CLASS}-section dds-h6`} style={{ fontSize: '14px', marginBottom: '1vh' }}>
                  Department
                </div>
                <div className={`${BASE_CLASS}-section-checkbox dds-h7`}>
                  {dropdownValues.department.length === subDepartments.length
                    && dropdownValues.department.length > 1 ? (
                    <>
                      {displaySelectedItems([], true)}
                    </>
                  ) : (
                    <>
                      {displaySelectedItems(dropdownValues.department, true)}
                    </>
                  )}
                </div>
              </div>
              <div>
                <div className={`${BASE_CLASS}-section dds-h6`} style={{ fontSize: '14px', marginBottom: '1vh' }}>
                  Sub department
                </div>
                <div className={`${BASE_CLASS}-section-checkbox dds-h7`}>
                  {dropdownValues.subDepartment.length === subDepartments.length
                    && dropdownValues.subDepartment.length > 1 ? (
                    <>
                      {displaySelectedItems([], true)}
                    </>
                  ) : (
                    <>
                      {displaySelectedItems(dropdownValues.subDepartment, true)}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className="dds-label"
                style={{ marginBottom: '3px' }}
              >
                Email ID
              </div>
              <input
                className={`${BASE_CLASS}-input dds-label`}
                value={email}
                style={{
                  marginBottom: IsValidEmail ? '2vh' : '0',
                  borderColor: !IsValidEmail && email.trim().length > 0 ? '#da291c' : '#d0d0ce'
                }}
                type="text"
                placeholder="Type email here"
                onChange={val => {
                  setEmail(val.target.value);
                }}
              />
              {!IsValidEmail && email.trim().length > 0 && <p className="error-msg">Enter a valid email id.</p>}
              {!IsValidEmail && (
                <div className={`${BASE_CLASS}-separator dds-label`}>
                  <div className={`${BASE_CLASS}-divider`} />
                  Or
                  <div className={`${BASE_CLASS}-divider`} />
                </div>
              )}
              <div className={`dropdownContainer`}>
                <Dropdown
                  className="dropdown"
                  viewOnly={viewOnly}
                  defaultValue={dropdownValues.functionalArea}
                  labelText={"Function "}
                  isRequired={false}
                  addLabel={true}
                  showValue={true}
                  isSearchBox={false}
                  onchange={handleFunctionChange}
                  placeholder={"Select"}
                  selectAllText="All"
                  options={functionalAreas}
                  disabled={IsValidEmail}
                  isMulti={true}
                  controlShouldRenderValue={true}
                  isClearable={false}
                  dropdownIndicatorStyle={{ color: "black" }}
                  containerStyle={{ display: "flex", gap: "2px", fontWeight: 400 }}
                />

                <Dropdown
                  className="dropdown"
                  defaultValue={dropdownValues.department}
                  viewOnly={viewOnly}
                  labelText={"Department"}
                  addLabel={true}
                  showValue={true}
                  onchange={handleDepartmentChange}
                  placeholder={getPlaceholderValues()}
                  selectAllText="All"
                  isMulti={true}
                  disabled={IsValidEmail || dropdownValues.functionalArea.length > 1}
                  options={departments}
                  controlShouldRenderValue={true}
                  isClearable={false}
                  dropdownIndicatorStyle={{ color: "black" }}
                  containerStyle={{ display: "flex", gap: "2px", fontWeight: 400 }}
                />

                <Dropdown
                  className="dropdown"
                  defaultValue={dropdownValues.subDepartment}
                  viewOnly={viewOnly}
                  labelText={"Sub-department"}
                  addLabel={true}
                  showValue={true}
                  onchange={handleSubDepartmentChange}
                  placeholder={getPlaceholderValues()}
                  selectAllText="All"
                  disabled={
                    IsValidEmail
                    || dropdownValues.functionalArea.length > 1
                    || dropdownValues.department.length > 1
                    || (dropdownValues.department.length === 1 && subDepartments.length === 0)
                  }
                  options={subDepartments}
                  isMulti={true}
                  controlShouldRenderValue={true}
                  isClearable={false}
                  dropdownIndicatorStyle={{ color: "black" }}
                  containerStyle={{ display: "flex", gap: "2px", fontWeight: 400 }}
                />
              </div>
            </>
          )}
        </div>

        <div className={`${BASE_CLASS}-section`}>
          <div className={`${BASE_CLASS}-section-header dds-h6`}>Location</div>
          <div className={`${BASE_CLASS}-section-checkbox dds-h7`}>
            {viewOnly ? (
              <>
                {displaySelectedItems(selectedLocations.map(item => ({ label: item })))}
              </>
            ) : (
              <>
                <div className={`${BASE_CLASS}-section-checkbox-single`}>
                  <Checkbox
                    disabled={IsValidEmail}
                    value={
                      locations.length > 0 &&
                      !locations.some(
                        (item) => !selectedLocations.includes(item.value)
                      )
                    }
                    onChange={(e) => {
                      if (!viewOnly) {
                        const tempLocs = selectedLocations.filter(
                          (item) => !locations.map((val) => val.value).includes(item)
                        );
                        if (e.target.checked) {
                          const locationsArray = locations.map((val) => val.value);
                          setSelectedLocations([...tempLocs, ...locationsArray]);
                        } else {
                          setSelectedLocations(tempLocs);
                        }
                      }
                    }}
                  />
                  All
                </div>
                {locations.length ? (
                  <>
                    <div className={`${BASE_CLASS}-section-checkbox-list`}>
                      {locations.map((item, i) => (
                        <div
                          className={`${BASE_CLASS}-section-checkbox-single`}
                          key={i}
                        >
                          <Checkbox
                            disabled={IsValidEmail}
                            value={
                              selectedLocations.filter(
                                (loc: any) => loc === item.value
                              ).length > 0
                            }
                            onChange={() => {
                              if (!viewOnly) {
                                if (
                                  selectedLocations.filter(
                                    (loc: any) => loc === item.value
                                  ).length > 0
                                ) {
                                  setSelectedLocations(
                                    selectedLocations.filter(
                                      (loc: any) => loc !== item.value
                                    )
                                  );
                                } else {
                                  setSelectedLocations((prev) => [
                                    ...prev,
                                    item.value,
                                  ]);
                                }
                              }
                            }}
                          />
                          <div className="ellipsis">{item.label}</div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>

        <div className={`${BASE_CLASS}-section`}>
          <div className={`${BASE_CLASS}-section-header dds-h6`}>
            Show columns
          </div>
          <div className={`${BASE_CLASS}-section-checkbox dds-h7`}>
            {viewOnly ? (
              <>
                {displaySelectedItems(selectedColumns.map(item => ({
                  label: ColumnsMap[`${item.name}` as keyof typeof ColumnsMap] || item.name
                })))}
              </>
            ) : (
              <>
                {columns.length ? (
                  <div className={`${BASE_CLASS}-section-checkbox-list`}>
                    {columns.map((item, i) => (
                      <div
                        className={`${BASE_CLASS}-section-checkbox-single`}
                        key={i}
                      >
                        <Checkbox
                          value={
                            selectedColumns.filter(
                              (col: any) =>
                                col.name === item.name &&
                                col.type === item.type &&
                                col.id === item.id
                            ).length > 0
                          }
                          onChange={() => {
                            if (!viewOnly) {
                              const isAlreadySelected =
                                selectedColumns.filter(
                                  (col: any) =>
                                    col.name === item.name &&
                                    col.type === item.type &&
                                    col.id === item.id
                                ).length > 0;

                              if (isAlreadySelected) {
                                setSelectedColumns(
                                  selectedColumns.filter(
                                    (col: any) =>
                                      !(
                                        col.name === item.name &&
                                        col.type === item.type &&
                                        col.id === item.id
                                      )
                                  )
                                );
                              } else {
                                setSelectedColumns((prev) => [...prev, item]);
                              }
                            }
                          }}
                        />
                        <div className="ellipsis">
                          {ColumnsMap[`${item.name}` as keyof typeof ColumnsMap] || item.name}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>

        {!viewOnly && (
          <div className="footer">
            <Button
              size={Size.m}
              className="generate-button"
              kind={Kind.primaryLoud}
              theme={themeObjState}
              label="Generate"
              isDisabled={!(dropdownValues.functionalArea.length > 0)}
              onClick={() => {
                if (validateOptions()) {
                  setShowGenerateModal(true);
                }
              }}
            />
            <Button
              size={Size.m}
              className="reset-button"
              kind={Kind.primary}
              theme={themeObjState}
              label="Reset"
              isDisabled={disableResetButton}
              onClick={resetPage}
            />
          </div>
        )}
      </div>

      {showGenerateModal && (
        <PopUpModel
          saveTitle={"Generate"}
          onClickClose={() => {
            setShowGenerateModal(false);
            setFileName("");
            setFormValidity({
              "Report name": false,
            });
          }}
          onSaveClick={() => {
            generateReport();
            setFormValidity({
              "Report name": false,
            });
          }}
          disabledSave={!isValidFields}
          Title={"Name the report"}
        >
          <div className={`${BASE_CLASS}-popup-body`}>
            <InputBox
              value={fileName}
              mainClassName="width-100"
              labelText={"Report name"}
              maxLength={64}
              minLength={5}
              errorText="name"
              addLabel={true}
              placeholder="Type name here"
              suffix=".xlsx"
              validators={[validateRequired, validateFileName]}
              onInputChange={handleUserInputChange}
              onChange={(value: any) => setFileName(value)}
            />
          </div>
        </PopUpModel>
      )}

      {showEmailModal && (
        <PopUpModel
          Title="Generating"
          onClickClose={() => {
            setShowEmailModal(false);
            setShowGenerateModal(false);
            setFileName("");
          }}
          onSaveClick={async () => {
            setShowEmailModal(false);
            setShowGenerateModal(false);
            setFileName("");
          }}
          saveTitle="Okay"
          closeTitle="Cancel"
          closeButtonClass="cancelColor"
        >
          <p style={{ fontSize: "14px" }}>
            {`Due to the large size of the data, generating the file may take some time. You will receive an email once it is complete.`}
          </p>
        </PopUpModel>
      )}

      <Notify />
      <BlockScreenMsg />
    </>
  );
};
export default GenerateReport;
